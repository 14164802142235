.collaboratorColumn-wrapper {
  .box {
    cursor: default;
    border-radius: 50%;
    margin-left: -7px;
    &:first-child {
      margin-left: 0;
    }

    .collaboratorColumn-initials {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: var(--white);
    }

    .collaboratorColumn-helper-text {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 500px;
      overflow: visible !important;
      box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
      border: 1px solid var(--wash-me);
      border-radius: 6px;
      background-color: var(--white);
      opacity: 1;
    }

    &.name {
      background-color: var(--true-v);
      &:nth-child(2n) {
        background-color: var(--brink-pink);
        border: 1px solid var(--white);
      }
      &:nth-child(3n) {
        background-color: var(--dodger-blue);
        border: 1px solid var(--white);
      }
    }
  }

  .single-box {
    margin: 0;
  }

  .hiddenuser {
    background-color: var(--dodger-blue);
    border: 1px solid var(--white);
    .collaboratorColumn-initials {
      color: var(--white);
    }
  }

  .addmore {
    cursor: pointer;
    border: 1px solid var(--white);
    background-color: var(--wash-me);
    z-index: 1;

    .collaboratorColumn-initials {
      font-size: 18px !important;
      font-weight: 600;
      color: var(--lynch);
    }
  }
}
