.toggleDropdownControl-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.toggleDropdownControl-portal {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  z-index: 10;
  padding: 12px 1px;
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
  border: 1px solid var(--wash-me);
  border-radius: 6px;
  background-color: var(--white);

  .irreversible-action-text {
    color: var(--red-dirt);
  }

  .toggleDropdown-horizontal-divider {
    height: 1px;
    background-color: var(--athens-gray);
    width: 100%;
    margin: 8px 0;
  }

  .toggleDropdownControl-section-header {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--lynch);
    padding: 0 16px 16px;
  }

  .toggleDropdownControl-list-item {
    cursor: pointer;
    padding: 0 20px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    line-height: 2.57;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--rhino);
    white-space: nowrap;
    &:hover {
      background-color: var(--wash-me);
    }
  }
  .cancelToggleDropDownClass {
    color: var(--red-dirt);
  }
  .disabled-item {
    pointer-events: none;
    color: var(--loblolly);
  }
}

.toggle-btn-container {
  display: flex;
  align-items: center;
  margin-right: 4px;
  border-radius: 50%;
  &:hover {
    background-color: var(--athens-gray);
  }
  svg {
    color: var(--rhino);
  }
}
