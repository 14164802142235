.text-input-wrapper {
  display: flex;
  height: 100%;
  border: 1px solid var(--loblolly);
  border-radius: 4px;
  padding: 0 8px;
  @media only screen and (min-width: 1024px) {
    padding: 1px 8px;
  }
  .text-input-input {
    flex: 1;

    input {
      height: 100%;
      padding: 0 12px;
      &,
      &:focus {
        border: 0;
      }
    }
  }

  .text-input-clear {
    cursor: pointer;
  }
}
