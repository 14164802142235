.radiobutton-wrapper {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  line-height: 20px;

  input[type='radio']:checked + .radiobutton-label {
    border-color: var(--watermelon);
    &:after {
      opacity: 1;
      transform: scale(1);
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    }
  }

  .radiobutton-label {
    float: left;
    position: relative;
    display: block;
    height: 16px;
    width: 16px;
    margin-right: 10px;
    border: 1px solid var(--watermelon);
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 8px;
      width: 8px;
      border-radius: 100%;
      background: var(--watermelon);
      opacity: 0.08;
      transform: scale(0);
      transition: all 0.2s ease;
    }
  }

  &:hover .label:after {
    transform: scale(3.6);
  }
}
