.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-f {
  position: fixed;
}

.full-sized-fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full-sized-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full-dimension {
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.fit-content-dimensions {
  width: fit-content;
  height: fit-content;
}

.fit-width {
  width: fit-content;
}

.fit-height {
  height: fit-content;
}

.full-view-width {
  width: 100vw;
}

.full-view-height {
  height: 100vh;
}

.full-view-dimensions {
  height: 100vh;
  width: 100vw;
}

.full-view-max-width {
  max-width: 100%;
}

.full-view-min-width {
  min-width: 100%;
}

.full-view-max-height {
  max-height: 100%;
}

.full-view-min-height {
  min-height: 100%;
}

.full-viewport-min-height {
  min-height: 100vh;
}

.full-viewport-min-width {
  min-width: 100vw;
}

.full-viewport-max-height {
  max-height: 100vh;
}

.full-viewport-max-width {
  max-width: 100vw;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}
