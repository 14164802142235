:root {
  --white: #fff;

  /* Gray palette */
  --brilliance: #fcfcfd;
  --wash-me: #f7f8fa;
  --athens-gray: #e8eaef;
  --mischka: #cfd3de;
  --loblolly: #babfcb;
  --manatee: #929baa;
  --lynch: #64728c;
  --fiord: #4c5578;
  --rhino: #2c3556;
  --port-gore: #1a1e3e;
  --ebony: #0c1021;

  /* Primary */
  --rose-white: #fffafa;
  --chablis: #fff4f5;
  --pippin: #ffe7e8;
  --your-pink: #ffbdc2;
  --geraldine: #fb8890;
  --brink-pink: #fb747d;
  --watermelon: #fa5762;
  --wild-watermelon: #fa5762;
  --coral-red: #f93946;
  --torch-red: #e03242;
  --brick-red: #d22b39;
  --cardinal: #c41e2e;

  /* Secondary */
  --twilight-blue: #f5feff;
  --bubbles: #e6fcff;
  --french-pass: #b3f5ff;
  --spray-teal: #79e2f2;
  --robins-egg-blue: #00c7e6;
  --cerulean: #00b8d9;
  --pacific-blue: #00a3bf;
  --deep-cerulean: #008da6;
  --all-ports: #038298;
  --blue-lagoon: #006f80;
  --blue-stone: #005c69;

  /* Error */
  --sauvignon: #fff7f5;
  --fair-pink: #ffebe6;
  --cranberry: #ed3177;
  --wax-flower: #ffbdad;
  --salmon: #ff8f73;
  --persimmon: #ff7452;
  --outrageous-orange: #ff5630;
  --pomegranate: #fa4416;
  --trinidad: #e93305;
  --red-dirt: #de350b;
  --grenadier: #d22b02;
  --milano-red: #bf2600;

  /* Warning */
  --eyeball: #fffdf6;
  --early-dawn: #fffae6;
  --butter-milk: #fff0b3;
  --kournikova: #ffe380;
  --amber: #ffc400;
  --web-orange: #ffab00;
  --tree-poppy: #ff991f;
  --pizazz: #ff8b00;
  --chilean-fire: #f97c01;
  --clementine: #f36c01;
  --red-stage: #ea5001;

  /* Success */
  --clear-day: #f4fff9;
  --scandal: #e3fcef;
  --magic-mint: #abf5d1;
  --spray: #79f2c0;
  --shamrock: #57d9a3;
  --emerald: #43c78f;
  --ocean-green: #36b37e;
  --green-haze: #069a69;
  --deep-sea: #00875a;
  --tropical-rain-forest: #007951;
  /* stylelint-disable color-hex-length */
  --fun-green: #006644;

  /* Violet Red */
  --cloud-break: #f8f0ff;
  --lavender-savor: #f5deff;
  --mauve: #f6b0ff;
  --lavender-magenta: #f68aef;
  --persian-pink: #f663d1;
  --brillian-rose: #f24aa8;
  --amor: #ed3177;
  --self-love: #ce2c6c;
  --hibiscus: #af2660;
  --disco: #902052;
  --claret: #721a44;

  /* Magenta */
  --lavender-blush: #fff2f3;
  --sweet-sixteen: #ffcad4;
  --carnation-pink: #fea2be;
  --brown-knapweed: #f77fae;
  --deep-blush: #eb61a2;
  --cerise: #dc4799;
  --royal-fuschia: #c93191;
  --fuschia-flourish: #b4289a;
  --purple-ink: #9d219d;
  --seance: #711a87;
  --scarlet-gum: #4c136f;

  /* Blue */
  --zircon: #f4f9ff;
  --pattens-blue: #deebff;
  --anakiwa: #b3d4ff;
  --malibu: #7cb5ff;
  --pool-water: #4c9aff;
  --dodger-blue: #2684ff;
  --azure-radiance: #1279ff;
  --blue-ribbon: #0065ff;
  --frosted-blueberries: #005ae4;
  --science-blue: #0052cc;
  --cobalt: #0747a6;

  /* Purple */
  --magnolia: #f8f6ff;
  --titan-white: #eae6ff;
  --moon-raker: #c0b6f2;
  --chetwode-blue: #998dd9;
  --true-v: #8777d9;
  --astro-purple: #7463cd;
  --fuschia-blue: #6554c0;
  --blue-violet: #5c4bb5;
  --gigas: #5243aa;
  --minsk: #403294;
  --jacksons-purple: #311b92;
}
