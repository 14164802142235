* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
}

a {
  cursor: pointer;
  &,
  &:hover {
    color: var(--rhino);
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
b,
strong {
  font-weight: 600;
}

h1,
h4,
h5 {
  font-family: 'Montserrat';
}

h1 {
  font-size: 2rem;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

:root {
  --top-padding: #{map-get($top-padding, desktop)};
  --horizontal-padding: #{map-get($horizontal-padding, desktop)};
  --header-height: #{$header-height};
  @include medium-viewport {
    --top-padding: #{map-get($top-padding, mobile)};
    --horizontal-padding: #{map-get($horizontal-padding, mobile)};
  }
}

.page-container {
  padding: var(--top-padding) var(--horizontal-padding);
}

.hidden {
  display: none !important;
}

.card {
  background-color: var(--white);
  padding: 1rem;
}

ul {
  list-style: none;
  li {
    margin-bottom: 1rem;
  }
}

li {
  list-style: none;
}

i {
  margin-right: 1rem;
}

.truncated {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
}

.truncated-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncated-notification-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.underlined-text {
  text-decoration: underline;
}

.card1 {
  background-color: var(--white);
  padding: 1rem;
  border: 1px solid var(--white);
  border-radius: 4px;

  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.2rem;
    img {
      width: 100%;
      max-width: 2.5rem;
    }
  }

  .header {
    font-size: 1rem;
    font-weight: 600;
    color: var(--rhino);
  }

  .sub-header {
    font-size: 0.9rem;
    color: gray;
    span {
      font-weight: 600;
      color: var(--rhino);
    }
  }

  .status {
    font-size: 0.8rem;
    padding: 0.2rem 2%;
    border-radius: 5px;
  }

  .br {
    height: 0.5rem;
  }

  @media (max-width: 320px) {
    padding: 0.4rem;
  }
}

input {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
  padding: 0 20px;
  color: var(--rhino);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #b1b5c8;
  }
  &:disabled {
    color: var(--rhino);
    background-color: var(--wash-me);
  }
}

datalist option {
  color: green;
}

select {
  height: 2rem;
  width: 100%;
  border-radius: 4px;
  background-color: var(--white);
  padding: 0.2rem 1rem;
  box-shadow: none;
  border: 0;
  color: var(--rhino);
  &:focus {
    border: 1px dashed var(--watermelon);
    outline: none;
  }
  &:disabled {
    color: var(--rhino);
    background-color: var(--wash-me);
  }
}

textarea {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 0;
  color: var(--rhino);

  &:focus {
    outline: none;
    border: 0;
  }

  &:disabled {
    color: var(--rhino);
    background-color: var(--wash-me);
  }

  &::placeholder {
    color: var(--manatee);
  }
}

button {
  background-color: var(--white);
  padding: 0.4rem 2rem;
  box-shadow: none;
  font-size: 0.95rem;
  border: 0;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    font-weight: 600;
  }
  &.primary {
    background-color: orange;
    color: var(--white);
  }
}

@media (min-width: 768px) {
  .container-area {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
  }
}

.ReactVirtualized__List:focus {
  outline: none !important;
}

.full-sized-fixed-element {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--white);
}

input[type='number'].without-spinner {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.text-align-center {
  text-align: center;
}

.relative {
  position: relative;
}

.online {
  filter: none;
  transition: filter 1s;
}

.offline {
  filter: grayscale(100%);
}

.transparent-tap {
  -webkit-tap-highlight-color: transparent;
}
