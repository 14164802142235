.simpleDropdown-wrapper {
  flex: 1;
  position: relative;

  span {
    border-radius: 8px;
    border: solid 1px var(--wash-me);
    padding: 0 8px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .label-name {
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
    }

    .permanent-label-text {
      color: var(--manatee);
      font-size: 12px;
      font-weight: 400;
      padding-right: 3px;
    }

    .label-value {
      white-space: nowrap;
      overflow: hidden;
      padding-left: 5px;
    }

    .label-downarrow {
      margin-left: 16px;
      white-space: nowrap;

      display: flex;
      align-items: center;

      svg {
        transition: transform 0.2s ease-in;
        & .active {
          transform: rotate(0);
        }
        &.inactive {
          transform: rotate(180deg);
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  .simpleDropdown-dropdownbox {
    position: absolute;
    z-index: 10;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    background-color: var(--white);
    padding: 12px 0.1rem;
    box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);

    max-height: 40vh;
    overflow-y: scroll;

    .simpleDropdown-dropdownbox-item {
      width: 100%;

      cursor: pointer;

      &:focus {
        outline: none;
        background-color: var(--wash-me);
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }
}
